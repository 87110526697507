import { initSetWidthToButtons } from '../utils/buttons';

const initBlock = async function( $block ) {
	const { initAnimations } = await import( './../modules/animations-on-scroll' );

	const loadMoreBtn = $block.find( '.js-view-more' );
	const blogContainer = $block.find( '.section-resources__items' );
	const categoryFilter = $block.find( '#resources-filter' );
	let selectedCategory = '';

	loadMoreBtn.on( 'click', ( e ) => {
		e.preventDefault();
		loadMorePosts.call( this );
	} );

	initSetWidthToButtons( loadMoreBtn );

	categoryFilter.on( 'change', () => {
		selectedCategory = categoryFilter.val();
		const currentUrl = new URL( window.location.href );

		currentUrl.pathname = currentUrl.pathname.replace( /\/page\/\d+/, '' );

		if ( selectedCategory ) {
			currentUrl.searchParams.set( 'category', selectedCategory );
		} else {
			currentUrl.searchParams.delete( 'category' );
		}

		loadMorePosts( currentUrl.href, true );
	} );

	function loadMorePosts( customRequestUrl = false, animatePosts = false ) {
		const ANIMATION_DURATION = 600;

		let requestUrl = loadMoreBtn.attr( 'href' );
		let replacePosts = false;
		let label = loadMoreBtn.find( '.btn-post-label' );
		let labelDefault = loadMoreBtn.attr( 'data-label-default' );
		let labelLoading = loadMoreBtn.attr( 'data-label-loading' );

		if ( customRequestUrl ) {
			requestUrl = customRequestUrl;
			replacePosts = true;
		} else if ( selectedCategory ) {
			const url = new URL( loadMoreBtn.attr( 'href' ), window.location.origin );
			const params = new URLSearchParams( url.search );
			params.set( 'category', selectedCategory );
			requestUrl = `${url.pathname}?${params.toString()}`;
		}

		blogContainer.addClass( 'is-loading' );
		loadMoreBtn.addClass( 'is-loading' );
		label.html( labelLoading );

		$.ajax( {
			url: requestUrl,
			type: 'GET',
			beforeSend: () => {
				if ( animatePosts ) {
					blogContainer.slideUp( ANIMATION_DURATION );
				}
			},
			success: ( response ) => {
				let html = $( response ).find( '.section-resources__items' ).html();

				if ( replacePosts ) {
					blogContainer.html( html );
				} else {
					blogContainer.append( html );
				}

				history.pushState( {}, '', requestUrl );

				let $newButton = $( response ).find( '.js-view-more' );

				if ( !$newButton.length || $newButton.hasClass( 'button--hidden' ) ) {
					loadMoreBtn.addClass( 'button--hidden' );
				} else {
					loadMoreBtn.attr( 'href', $newButton.attr( 'href' ) );
					loadMoreBtn.removeClass( 'button--hidden' );
				}

				if ( !html.trim() ) {
					loadMoreBtn.addClass( 'button--hidden' );
				}

				blogContainer.removeClass( 'is-loading' );
				loadMoreBtn.removeClass( 'is-loading' );
				label.html( labelDefault );

				initAnimations();
			},
			complete: () => {
				blogContainer.slideDown( ANIMATION_DURATION );
			}
		} );
	}
};

app.loadBlock( initBlock, 'resources', '.js-section-resources' );

/**
 * Calculates the maximum width needed to display all data attribute values of a button.
 *
 * @param {jQuery} $button - The jQuery button element to measure
 * @returns {number} The maximum width needed for the data attribute text
 */
const calculateDataAttributesWidth = ( $button ) => {
	let maxWidth = 0;

	// Get all data attributes that contain text
	const dataAttributes = Array.from( $button[0].attributes )
		.filter( ( attr ) => attr.name.startsWith( 'data-' ) )
		.map( ( attr ) => $button.attr( attr.name ) )
		.filter( ( value ) => typeof value === 'string' );

	// Create temporary span to measure data attribute text widths
	const $tempSpan = $( '<span>' ).css( {
		'visibility': 'hidden',
		'position': 'absolute',
		'display': 'inline-block'
	} ).appendTo( $button );

	// Calculate max width including data attributes
	dataAttributes.forEach( ( text ) => {
		$tempSpan.text( text );
		maxWidth = Math.max( maxWidth, $tempSpan.outerWidth() );
	} );

	// Remove temporary span
	$tempSpan.remove();

	return maxWidth;
};

/**
 * Calculates the maximum width needed to display all spans of a button.
 *
 * @param {jQuery} $button - The jQuery button element to measure
 * @returns {number} The maximum width needed for the spans
 */
const calculateSpansWidth = ( $button ) => {
	const $spans = $button.find( 'span' );

	let maxWidth = 0;

	$spans.each( ( _, span ) => {
		const $span = $( span );

		$span.css( 'display', 'inline-block' );
		maxWidth = Math.max( maxWidth, $span.outerWidth() );
		$span.css( 'display', '' );
	} );

	return maxWidth;
};

/**
 * Sets the width of the button to its largest possible state.
 *
 * @param {jQuery} $button
 * @returns {void}
 */
const setWidthToButton = ( $button ) => {
	$button.each( ( _, button ) => {
		const $button = $( button );

		$button.css( 'width', 'auto' );

		const buttonWidthBuffer = 10;
		const buttonPadding = parseInt( $button.css( 'padding-left' ) ) + parseInt( $button.css( 'padding-right' ) );

		const dataAttributesWidth = calculateDataAttributesWidth( $button );
		const spansWidth = calculateSpansWidth( $button );
		const maxWidth = Math.max( dataAttributesWidth, spansWidth );

		$button.css( 'width', maxWidth + buttonPadding + buttonWidthBuffer );
	} );
};

/**
 * Initializes the setting of the width of the button to its largest possible state.
 *
 * @param {jQuery} $button
 * @returns {void}
 */
export const initSetWidthToButtons = ( $button ) => {
	setWidthToButton( $button );

	$( window ).on( 'resize', () => {
		setTimeout( () => setWidthToButton( $button ), 1000 );
	} );
};
